<script lang="ts">
	import MdiSearchWeb from '~icons/mdi/search-web';
	let algoliaTriggerButton: HTMLButtonElement;
	let autocompleteContainer: HTMLDivElement;
	async function clickFunction() {
		if (!algoliaTriggerButton) {
			import('./algoliaAutocomplete').then((m) => {
				m.setupAutocomplete(autocompleteContainer).then(() => {
					algoliaTriggerButton = document.getElementsByClassName(
						'aa-DetachedSearchButton'
					)[0] as HTMLButtonElement;
					algoliaTriggerButton.click();
				});
			});
		} else {
			algoliaTriggerButton.click();
		}
	}
</script>

<div id="autocomplete" class="hidden" bind:this={autocompleteContainer} />
<button
	class="mx-auto mt-2 w-full max-w-sm bg-main-text/5 px-2 text-sm hover:bg-blue-500/20 hover:text-blue-dark"
	title="Search"
	on:click={clickFunction}
>
	<div class="mx-auto flex h-full w-fit items-center">
		<MdiSearchWeb class="inline-block" />SEARCH
	</div>
</button>
