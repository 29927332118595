<script lang="ts">
	import { onMount } from 'svelte';

	import {
		allConsentDenied,
		allConsentGranted,
		initialiseConsentStatus,
		isConsentSettingUpToDate,
		type ConsentPreferences
	} from './consentManagerUtils';

	let consentStatus: ConsentPreferences | undefined;

	$: console.log(consentStatus);

	let consentDialog: HTMLDialogElement;

	onMount(() => {
		consentStatus = initialiseConsentStatus();

		if (consentStatus == undefined || !isConsentSettingUpToDate(consentStatus)) {
			consentDialog.show();
		}
	});

	export let settingsPage: string;
</script>

<dialog id="consentDialog" class="consentDialog" bind:this={consentDialog}>
	<section class="info">
		<h2>This site uses cookies for analytics.</h2>
		<p>Please choose whether to accept them.</p>
	</section>
	<section class="buttonsAndLinks">
		<div class="buttons">
			<button
				class="button denyButton flex-grow"
				on:click={() => {
					allConsentDenied();
					consentDialog.close();
				}}>Reject</button
			>
			<!-- svelte-ignore a11y-autofocus -->
			<button
				class="button allowButton flex-grow"
				autofocus
				on:click={() => {
					allConsentGranted();
					consentDialog.close();
				}}>Accept</button
			>
		</div>
		<div class="moreSettings">
			<a class="moreSettings-link" href={settingsPage}>Show preferences</a>
		</div>
	</section>
</dialog>

<style>
	dialog.consentDialog {
		z-index: 400;
		padding: 1rem;
		font-size: 0.9rem;
		max-width: min(50ch, calc(100vw - 2rem));
		margin: 0 1rem 1rem auto;
		position: fixed;
		bottom: 0;
		box-shadow:
			0 10px 15px -3px rgb(0 0 0 / 0.1),
			0 4px 6px -4px rgb(0 0 0 / 0.1);

		& .info {
			padding-bottom: 0.5rem;

			& p {
				font-size: 0.8rem;
			}
		}

		& .buttons {
			display: flex;
			gap: 0.4rem;

			& button:hover {
				background-color: #333;
			}

			& .denyButton {
				background-color: #555;
				flex-grow: 2;
			}
			& .allowButton {
				flex-grow: 3;
			}
		}

		& .moreSettings {
			padding: 0.3rem 0 0 0;
			font-size: 0.8rem;

			& .moreSettings-link {
				/* align-self: center; */
				color: rgb(34, 82, 185);
				text-decoration: underline;
				&:hover {
					color: var(--main-text-color);
				}
			}
		}
	}
</style>
