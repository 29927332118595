<script lang="ts">
	import { page } from '$app/stores';
	import SvgIcon from '@samsveltecomponents/core/Icons/SvgIcon.svelte';
	import { soundcloud, youtube, githubLogo } from '@samsveltecomponents/core/Icons/icons';
	import { beforeNavigate } from '$app/navigation';
	import { browser } from '$app/environment';
	const menu_items = [
		{ title: 'About', href: '/about' },
		{ title: 'Works', href: '/works' },
		{ title: 'Events', href: '/events' },
		{ title: 'Contact', href: '/contact' }
	];

	let checked: boolean;
	beforeNavigate(() => {
		checked = false;
	});

	$: if (browser) {
		// This freezes the background and then keeps it in the correct position
		// when the menu opens and closes.
		if (checked) {
			const currentScrollPixels = window.scrollY;
			document.body.style.position = 'fixed';
			document.body.style.top = `-${currentScrollPixels}px`;
		} else {
			const scrollY = document.body.style.top;
			document.body.style.position = '';
			document.body.style.top = '';
			window.scrollTo(0, parseInt(scrollY || '0') * -1);
		}
	}
</script>

<div class="mb-8 h-16 md:hidden" />
<header
	class="header fixed -top-8 mb-8 h-auto pt-8 backdrop-blur backdrop-saturate-200 md:sticky"
	class:dim-others={checked}
>
	<div class="menu col-start-2 flex flex-row flex-wrap items-center py-2">
		<div class="">
			<a data-sveltekit-preload-data href="/" class="menulink">Sam Greening</a>
		</div>
		<div class="grow" />
		<input title="menu toggle" class="side-menu" type="checkbox" id="side-menu" bind:checked />
		<label class="hamb" for="side-menu"><span class="hamb-line" /></label>
		<nav class="nav">
			<ul class="items-center md:grid md:grid-flow-col">
				{#each menu_items as { title, href }}
					<li class="md:float-left">
						<a
							{title}
							class="menulink"
							{href}
							aria-current={$page.url.pathname.startsWith(href) ? 'page' : undefined}
							data-sveltekit-preload-data
						>
							{title}
						</a>
					</li>
				{/each}
				<li class="float-left">
					<a
						title="SoundCloud"
						class="menulink"
						target="_blank"
						rel="noreferrer"
						href="https://soundcloud.com/samgreening"
					>
						<SvgIcon icon={soundcloud} />
					</a>
				</li>
				<li class="float-left">
					<a
						title="YouTube"
						class="menulink"
						target="_blank"
						rel="noreferrer"
						href="https://www.youtube.com/channel/UCAXyelE5pk47QjFedXnTDgQ"
					>
						<SvgIcon icon={youtube} />
					</a>
				</li>
				<li class="float-left">
					<a
						title="GitHub"
						class="menulink"
						target="_blank"
						rel="noreferrer"
						href="https://github.com/SG60/"
					>
						<SvgIcon icon={githubLogo} />
					</a>
				</li>
			</ul>
		</nav>
	</div>
</header>

<style lang="scss">
	.header {
		color: rgb(var(--main-text-color));
		background-color: rgba(var(--background-color), 0.9);
		display: grid;
		grid-template-columns:
			minmax(1.2rem, 1fr)
			minmax(auto, 45rem)
			minmax(1.2rem, 1fr);
		grid-column: 1/4;
		z-index: 100;

		.menu {
			display: flex;
			flex-wrap: wrap;
			border-bottom: solid 3px var(--header-bottom-line-color);
			margin-left: -12px;
			width: calc(100% + 24px);
		}

		.menulink {
			text-decoration: none;
			font-weight: 500;
			font-size: 1em;
			padding: 8px;
			display: block;
			transition: color 0.3s;
			margin: 4px;
			border-radius: 0.13rem;

			color: rgb(var(--main-text-color));
			fill: rgb(var(--main-text-color));

			&:hover {
				color: var(--header-link-hover);
				background: rgb(var(--main-text-color), 0.1);
				fill: var(--header-link-hover);
			}
		}
		[aria-current] {
			text-decoration: underline;
			text-decoration-thickness: 3px;
			font-weight: bold;
		}

		ul {
			padding: 0;
			margin: 0;
		}
	}

	.nav {
		width: 100%;
		height: 100%;
		overflow: hidden;
		max-height: 0;
		transition: max-height 0.3s;
		background: none;
	}

	@media (min-width: 768px) {
		.nav {
			max-height: none;
			width: auto;
			top: 0;
			position: relative;
		}

		.hamb {
			display: none;
		}
	}

	/* Menu Icon */
	.hamb {
		cursor: pointer;
		float: right;
		padding: 20px 10px;
	}

	/* Style label tag */
	.hamb-line {
		display: block;
		height: 2px;
		position: relative;
		width: 24px;

		&,
		&::after,
		&::before {
			background: rgb(var(--main-text-color));
		}

		&::before,
		&::after {
			content: '';
			display: block;
			height: 100%;
			position: absolute;
			transition: all 0.2s ease-out;
			width: 100%;
		}

		&::before {
			top: 5px;
		}

		&::after {
			top: -5px;
		}
	}

	/* Toggle menu icon */
	.side-menu {
		display: none;
		&:checked {
			~ .hamb .hamb-line::before {
				transform: rotate(-45deg);
				top: 0;
			}
			~ .hamb .hamb-line::after {
				transform: rotate(45deg);
				top: 0;
			}
			~ nav.nav {
				max-height: calc(100vh - 6rem);
				overflow-y: auto;
			}

			~ .hamb .hamb-line {
				background: transparent;
			}
		}
	}
</style>
