<script>
	import { beforeNavigate, afterNavigate } from '$app/navigation';

	import { tweened } from 'svelte/motion';
	import { cubicOut } from 'svelte/easing';

	// Duration of transitions in milliseconds
	const duration = 400;
	// Svelte store with slew
	const progress = tweened(0, { duration: duration, easing: cubicOut });

	let opacity = 0;

	beforeNavigate(() => {
		progress.set(0, { duration: 0 });
		opacity = 1;
		$progress = 0.7;
	});

	afterNavigate(() => {
		$progress = 1;
		setTimeout(() => {
			opacity = 0;
		}, duration - 200);
		setTimeout(() => {
			progress.set(0, { duration: 0 });
		}, duration);
	});
</script>

<div class="fixed top-0 left-0 z-[999] h-2 w-full md:h-1">
	<!-- This is the actual progress bar -->
	<div
		class="h-full w-[var(--width)] bg-blue-default transition-opacity duration-300"
		style:opacity
		style:--width="{$progress * 100}%"
	/>
</div>
